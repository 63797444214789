import * as React from "react"
import { graphql } from "gatsby"
import FrontmatterPageContext from "~context/FakePageContext"
import { main } from "~vanilla/layout-default.css"
import { mainArea } from "~vanilla/layout-default-full.css"
import { FavHead } from "~components/Head/FavHead"

import LayoutCore from "~templates/layout-core"

let frontmatter = {
  section: "none",
  section_safe: "none",
  title: "Since",
  lang: "en",
}

/* ***** this is all legacy code, i didnt convert it for time reasons. this page is only for internal use, it doesnt have to be good. ***** */
const DURATION_IN_SECONDS = {
  epochs: ["year", "month", "day", "hour", "minute", "second"],
  year: 31536000,
  month: 2592000,
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1,
};

function getDuration(seconds) {
  let epoch, interval;

  for (var i = 0; i < DURATION_IN_SECONDS.epochs.length; i++) {
    epoch = DURATION_IN_SECONDS.epochs[i];
    interval = Math.floor(seconds / DURATION_IN_SECONDS[epoch]);
    if (interval >= 1) {
      return { interval: interval, epoch: epoch };
    }
  }
}

function timeSince(date) {
  let seconds = Math.floor((new Date() - new Date(date)) / 1000);
  let duration = getDuration(seconds);
  let suffix = duration.interval > 1 || duration.interval === 0 ? "s" : "";
  return duration.interval + " " + duration.epoch + suffix;
}
/* ***** ***** */

const SincePage = ({ data }) => {

  const { buildTime } = data.site

  const dateObj = new Date(buildTime);

  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutCore>
          <div className={`${mainArea} grid-main`}>
            <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
              <div className="content-content">
                The site was last built at: <code>{buildTime}</code><br />

                UTC:   <code><time id='build_at' datetime={buildTime}>{dateObj.toUTCString()}</time></code><br />
                Local: <code><time id='local_at' datetime={buildTime}>{dateObj.toString()}</time></code><br />

                This was approximately <output id="live_since" style={{textDecoration: "underline dotted"}}>{timeSince(buildTime)}</output> ago.
              </div>
            </main>
          </div>
        </LayoutCore>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default SincePage

export const Head = (props) => {
  return <>
    <title>{frontmatter.title}</title>
    <FavHead />
  </>
}

export const sinceQuery = graphql`
  query {
    site {
      buildTime
    }
  }
`
